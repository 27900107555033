import clsx from "clsx";
import { FC } from "react";
import { Anchor } from "@/components/Header/Anchor/Anchor";
import { Section } from "@/components/Html/Section";
import { pagesPath } from "@/lib/$path";
import { cardIds } from "@/lib/ga4";
import { Title } from "../Title/Title";
import { LinkCard } from "./LinkCard/LinkCard";

export const Services: FC = () => {
  return (
    <Section className={clsx("kdot-container !py-[48px]")}>
      <Anchor id="services" />
      <Title title="サービス" />
      <ul className={clsx("grid grid-cols-1 gap-x-8 gap-y-4 lg:grid-cols-2 xl:grid-cols-3")}>
        <LinkCard
          id={cardIds.kaihatsu_shien_detail}
          title="開発プロジェクト立ち上げ支援"
          description="ソフトウェア開発プロジェクト運営の経験が浅いお客様に並走し、プロジェクトの立上げ・運営、開発実装までサポートします。"
          href={pagesPath.service.dev_project_startup_support.$url()}
        />
        <LinkCard
          id={cardIds.kaihatsu_shien_detail}
          title="Webシステム開発"
          description="お客様のビジネスに寄り添ったシステムの設計、最新・最適技術の選定により価値を発揮するシステムを開発・運用"
          href={pagesPath.service.web_system_dev.$url()}
        />
        <LinkCard
          id={cardIds.kaihatsu_shien_detail}
          title="技術顧問: 一般向け"
          description="現代のビジネスでは必須となったITの波を乗りこなすための羅針盤を提供"
          href={pagesPath.service.dev_advisor_general.$url()}
        />
        <LinkCard
          id={cardIds.kaihatsu_shien_detail}
          title={
            <>
              <div>技術顧問:</div>
              <div>プロフェッショナル向け</div>
            </>
          }
          description="お客様の技術力を強化し、さらなるビジネスチャンスの開拓をサポート"
          href={pagesPath.service.dev_advisor_professional.$url()}
        />
        <LinkCard
          id={cardIds.kaihatsu_shien_detail}
          title="データ活用総合サービス"
          description="ビジネス上の意思決定にクリティカルな影響を与えるデータ活用を総合サポート"
          href={pagesPath.service.data_analysis.$url()}
        />
      </ul>
    </Section>
  );
};
