/* eslint-disable spellcheck/spell-checker */
export const cardIds = {
  kaihatsu_shien_detail: "kaihatsu_shien_detail",
  webapp_review_detail: "webapp_review_detail",
  web_dev_detail: "web_dev_detail",
  knowledge_detail: "knowledge_detail",
  study_meeting_detail: "study_meeting_detail",
  webapp_test_detail: "webapp_test_detail",
  teamdev_detail: "teamdev_detail",
  aimodel_detail: "aimodel_detail",
  library_survey_detail: "library_survey_detail",
};
