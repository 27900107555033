import clsx from "clsx";
import { FC } from "react";
import { Anchor } from "@/components/Header/Anchor/Anchor";
import { Section } from "@/components/Html/Section";
import { Title } from "../Title/Title";
import { Map } from "./Map/Map";

export const Company: FC = () => {
  return (
    <Section className={clsx("kdot-container !pb-[70px] !pt-[40px]")}>
      <Anchor id="company" />
      <Title title="会社情報" />
      <div className="flex flex-col sm:flex-row">
        <dl className="mb-10 mr-0 w-auto sm:mb-0 sm:mr-[10%] sm:w-[35%]">
          <div className="flex border-b-[1px] border-solid border-black py-[10px] text-[0.75rem]">
            <dt className="w-[40%] pt-[3px]">事業所名</dt>
            <dd className="w-[60%]">株式会社KDOT</dd>
          </div>
          <div className="flex border-b-[1px] border-solid border-black py-[10px] text-[0.75rem]">
            <dt className="w-[40%] pt-[3px]">設立</dt>
            <dd className="w-[60%]">2023年2月24日</dd>
          </div>
          <div className="flex border-b-[1px] border-solid border-black py-[10px] text-[0.75rem]">
            <dt className="w-[40%] pt-[3px]">代表取締役</dt>
            <dd className="w-[60%]">森⼭ 和樹</dd>
          </div>
          <div className="flex border-b-[1px] border-solid border-black py-[10px] text-[0.75rem]">
            <dt className="w-[40%] pt-[3px]">事業内容</dt>
            <dd className="w-[60%]">
              アプリケーションの企画開発、運用
              <br />
              開発に関するコンサルティング
            </dd>
          </div>
          <div className="flex border-b-[1px] border-solid border-black py-[10px] text-[0.75rem]">
            <dt className="w-[40%] pt-[3px]">所在地</dt>
            <dd className="w-[60%]">
              〒101-0051
              <br />
              東京都千代田区神田神保町
              <br />
              ２丁目１４−１１
              <br />
              朝日神保町プラザ909
            </dd>
          </div>
        </dl>
        <Map className="h-[400px] w-full sm:w-[60%]" />
      </div>
    </Section>
  );
};
