import clsx from "clsx";
import dayjs from "dayjs";
import Link from "next/link";
import { FC } from "react";
import { Image } from "@/components/Image/Image";
import { Tags } from "@/components/Tags/Tags";
import { useDayjsToString } from "@/components/useDayjsToString";
import { useHover } from "@/components/useHover";
import { pagesPath } from "@/lib/$path";
import { TechBlog } from "@/lib/contentful/contentModel/techBlog";
import { getImageSize, getImageUrl } from "@/lib/contentful/util";
import { isNonNull } from "@/lib/guard";
import { PostAuthor } from "../PostAuthor/PostAuthor";

interface Props {
  data: DeepPick<
    TechBlog,
    | "fields.importedAt"
    | "fields.slug"
    | "fields.image"
    | "fields.title"
    | "fields.author"
    | "fields.tags"
    | "sys.createdAt"
  >;
}

export const PostCard: FC<Props> = ({ data }) => {
  const { isHovered, onMouseEnter, onMouseLeave } = useHover();

  const date = useDayjsToString(dayjs(data.fields.importedAt ?? data.sys.createdAt), "YYYY/MM/DD");

  return (
    <div className="flex flex-col overflow-hidden rounded-lg border border-solid border-gray-pale bg-white">
      <Link
        href={pagesPath.kdotdev._slug(data.fields.slug).$url()}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {data.fields.image ? (
          <Image
            className="h-[160px] w-full border-b border-solid border-gray-pale object-cover"
            src={getImageUrl(data.fields.image)}
            width={getImageSize(data.fields.image)?.width}
            height={getImageSize(data.fields.image)?.height}
            alt={String(data.fields.image.fields.title)}
            sizes="(max-width: 768px) 100vw, (max-width: 992px) 250px, 350px"
            quality={40}
          />
        ) : (
          <Image
            className="h-[320px] w-full border-b border-solid border-gray-pale object-cover"
            src="/images/kdotdev/banner.png"
            width={804}
            height={400}
            alt={"Blog Image"}
          />
        )}
      </Link>
      <div className="p-[16px] pb-0">
        <Link href={pagesPath.kdotdev._slug(data.fields.slug).$url()}>
          <h3 className={clsx("text-lg font-bold hover:underline", isHovered ? "underline" : "")}>
            {data.fields.title}
          </h3>
        </Link>
        {data.fields.author && <PostAuthor author={data.fields.author} />}
        <div className="text-right font-roboto text-xs text-gray-600">{date}</div>
      </div>
      <div className="p-[16px] pt-0">
        <hr className="my-2 rounded-lg border border-solid border-gray-pale bg-white" />
        {data.fields.tags && <Tags tags={data.fields.tags.filter(isNonNull)} />}
      </div>
    </div>
  );
};
