import { Loader } from "@googlemaps/js-api-loader";
import clsx from "clsx";
import { ComponentProps, FC, useEffect } from "react";
import mapOptions from "./mapOptions.json";

export const Map: FC<Omit<ComponentProps<"div">, "children">> = ({ className, ...props }) => {
  useEffect(() => {
    void initMap();
  }, []);

  const initMap = async () => {
    const loader = new Loader({
      apiKey: process.env.NEXT_PUBLIC_MAP_API_KEY ?? "",
      version: "weekly",
    });

    await loader.importLibrary("maps");

    const myLatLng = { lat: 35.697034906343426, lng: 139.75715687310415 };
    const mapElm = document.getElementById("map");

    if (!mapElm) return;
    const map = new google.maps.Map(mapElm, {
      center: myLatLng,
      zoom: 15,
      styles: mapOptions.styles,
    });

    new google.maps.Marker({
      position: myLatLng,
      map,
    });
  };

  return (
    <div className={clsx(className, "rounded border-[1px] border-solid border-gray-pale")} {...props} id="map"></div>
  );
};
