import clsx from "clsx";
import Link from "next/link";
import { ComponentProps, FC, useRef } from "react";
import { Heading } from "@/components/Html/Heading";
import { Section } from "@/components/Html/Section";
import { Image } from "@/components/Image/Image";

interface Props extends Omit<ComponentProps<typeof Link>, "children" | "title"> {
  title: string | JSX.Element;
  description: string;
}

export const LinkCard: FC<Props> = ({ title, description, className, id, ...props }) => {
  const contentRef = useRef<HTMLParagraphElement>(null);

  return (
    <Link
      id={id}
      className={clsx(
        (className =
          "flex w-full cursor-pointer flex-col items-stretch rounded border-[1px] border-solid border-gray-pale bg-white p-4 text-left text-black transition-[min-height] duration-[250]"),
        className,
      )}
      {...props}
    >
      <Section className="flex h-full flex-1 flex-col">
        <hgroup className="mb-[10px] flex gap-[10px] border-b-[1px] border-solid border-gray-200 pb-[4px]">
          <Heading className="flex-1 text-[1rem] font-medium">{title}</Heading>
          <Image
            src="/images/services/icons/right-arrow.svg"
            alt=""
            width={24}
            height={24}
            className="mt-[2px] h-[24px] w-[24px]"
          />
        </hgroup>
        <p ref={contentRef} className="overflow-hidden break-all text-[0.75rem] !transition-[height] !duration-[250]">
          {description}
        </p>
      </Section>
    </Link>
  );
};
