import { TechBlog } from "@/lib/contentful/contentModel/techBlog";

export const findByTags = (posts: TechBlog[], tags: string[]) => {
  return posts.filter((post) => {
    return post.fields.tags
      ?.filter((tag): tag is NonNullable<typeof tag> => tag != null)
      .map((tag) => tag.fields.name)
      .some((name) => tags.includes(name));
  });
};

export const getImageUrl = (image?: PreviewAsset) => {
  return image?.fields?.file?.url ? "https:" + image.fields.file.url : "/images/kdotdev/banner.png";
};

export const getImageSize = (image?: PreviewAsset) => {
  return image?.fields?.file?.details && "image" in image.fields.file.details
    ? image.fields.file.details.image
    : undefined;
};
