import Link from "next/link";
import { FC } from "react";
import { Image } from "@/components/Image/Image";
import { pagesPath } from "@/lib/$path";
import { Author } from "@/lib/contentful/contentModel/author";
import { getImageUrl } from "@/lib/contentful/util";

interface Props {
  author: Author;
}

export const PostAuthor: FC<Props> = ({ author }) => {
  return (
    <Link
      className="flex items-center py-[8px] hover:underline"
      href={pagesPath.kdotdev.author._name(author.fields.name).$url()}
    >
      <Image
        className="rounded-full object-cover"
        src={getImageUrl(author.fields.avatar)}
        alt={author.fields.name}
        width={40}
        height={40}
      ></Image>
      <div className="ml-3 text-xs text-black">
        {author.fields.name} <span>({author.fields.nameKanji})</span>
      </div>
    </Link>
  );
};
