import { NextSeo } from "next-seo";
import React, { ComponentProps } from "react";
import { Company } from "@/components/home/Company/Company";
import { Services } from "@/components/home/Services/Services";
import { Top } from "@/components/home/Top/Top";
import { News as NewsModel } from "@/lib/contentful/contentModel/news";
import { TechBlog } from "@/lib/contentful/contentModel/techBlog";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { Section } from "../Html/Section";
import { PostCard } from "../PostCard/PostCard";
import { Media } from "./Media/Media";
import { News } from "./News/News";

interface Props {
  news: NewsModel[];
  posts: (ComponentProps<typeof PostCard>["data"] & DeepPick<TechBlog, "sys.id">)[];
}

export const Home: React.FC<Props> = ({ news, posts }) => {
  return (
    <Section>
      <NextSeo description="KDOTは技術を使って利益を生むことを目的に、お客様の経営課題の解決を支援します。" />
      <Header className="fixed z-[1] w-full bg-white" />
      <Top />
      <Services />
      <News news={news} />
      <Media posts={posts} />
      <div className="bg-particle bg-cover bg-center bg-no-repeat">
        <Company />
      </div>
      <Footer />
    </Section>
  );
};
