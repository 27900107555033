import clsx from "clsx";
import { ComponentProps, FC } from "react";
import { Section } from "@/components/Html/Section";
import { PostCard } from "@/components/PostCard/PostCard";
import { pagesPath } from "@/lib/$path";
import { TechBlog } from "@/lib/contentful/contentModel/techBlog";
import { Title } from "../Title/Title";
import { Card } from "./Card/Card";

interface Props {
  posts: (ComponentProps<typeof PostCard>["data"] & DeepPick<TechBlog, "sys.id">)[];
}

export const Media: FC<Props> = ({ posts }) => {
  return (
    <Section className={clsx("kdot-container !py-[48px]")}>
      <Title title="運営メディア" />
      <p className="mb-[32px] lg:w-2/3">
        技術情報の発信を通して私達は自身の知識をアップデートし続けています。
        <br />
        お客様からのご相談に対して、最新の情報で回答します。
      </p>
      <div className={"grid grid-cols-1 gap-8 lg:grid-cols-3"}>
        <Card
          title="K.DEV"
          description="KDOTの知見を集めた技術メディア"
          icon="/images/logo/back.png"
          href={pagesPath.kdotdev.pages._page_number(1).$url()}
          target="_blank"
        />
      </div>
      <div className="mt-10 grid grid-cols-1 gap-10 md:grid-cols-2 xlp:grid-cols-3">
        {posts.map((item) => (
          <PostCard data={item} key={item.sys.id} />
        ))}
      </div>
    </Section>
  );
};
