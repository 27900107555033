import { GetStaticProps } from "next";
import { ComponentProps } from "react";
import { Home } from "@/components/home/Home";
import { buildClient } from "@/lib/contentful/client";
import { NewsFields } from "@/lib/contentful/contentModel/news";
import { getTechBlogsOrderByDate } from "@/lib/contentful/getTechBlogsOrderByDate";

export const getStaticProps: GetStaticProps<ComponentProps<typeof Home>> = async () => {
  const client = buildClient();

  const { items: news } = await client.getEntries<NewsFields>({
    content_type: "news",
    order: ["-fields.date"],
    select: ["fields.title", "fields.image", "fields.slug", "fields.date", "fields.tags", "sys.id"],
    limit: 6,
  });

  const { items: posts } = await getTechBlogsOrderByDate(client, 0, 3);

  return {
    props: {
      news,
      posts,
    },
  };
};

export default Home;
