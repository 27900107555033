import Link from "next/link";
import { ComponentProps, FC, HTMLAttributeAnchorTarget } from "react";
import { UrlObject } from "url";
import { Image } from "@/components/Image/Image";

interface Props extends Omit<ComponentProps<"li">, "children"> {
  title: string;
  description: string;
  icon: string;
  target?: HTMLAttributeAnchorTarget;
  href: UrlObject;
}

export const Card: FC<Props> = ({ title, description, icon, href, target }) => {
  return (
    <Link
      className="flex items-center rounded-[10px]  border-[1px] border-solid border-gray-pale p-5"
      href={href}
      target={target}
    >
      <div className="flex-1">
        <div className="flex items-center">
          <Image width={32} height={32} src={icon} alt=""></Image>
          <span className="ml-[8px] text-[16px] font-medium not-italic">{title}</span>
        </div>
        <div className="mt-3 text-[12px] font-normal not-italic text-gray-500">{description}</div>
      </div>
      <Image width={16} height={16} alt="" src="/images/dupplicate.svg" />
    </Link>
  );
};
