import clsx from "clsx";
import { ComponentProps, FC, ReactNode } from "react";
import { Heading } from "../../Html/Heading";

interface Props extends Omit<ComponentProps<"hgroup">, "children" | "title"> {
  title: ReactNode;
  heading?: "1" | "2" | "3" | "4" | "5" | "6";
}

export const Title: FC<Props> = ({ className, title, heading }) => {
  return (
    <Heading
      className={clsx(
        "m-0 mb-[32px] max-w-content border-b-8 border-solid border-red pb-[8px] text-[2.5rem]",
        className,
      )}
      heading={heading ?? "2"}
    >
      {title}
    </Heading>
  );
};
