import clsx from "clsx";
import Link from "next/link";
import { FC } from "react";
import { pagesPath } from "@/lib/$path";
import { Tag } from "@/lib/contentful/contentModel/tag";

interface Props {
  tags: DeepPick<Tag, "fields.slug" | "fields.colorCode" | "fields.name">[];
  className?: string;
}

export const Tags: FC<Props> = ({ tags, className }) => {
  return (
    <div className={clsx("flex flex-wrap items-center gap-[8px]", className)}>
      {tags.map((tag) => (
        <Link
          href={tag.fields.slug ? pagesPath.kdotdev.tag._slug(tag.fields.slug).$url() : "/"}
          className="flex items-center text-right text-[12px] hover:underline"
          key={tag.fields.slug}
        >
          <div
            className="mr-[4px] h-[8px] w-[8px] rounded-[4px] border border-solid border-gray-100"
            style={{
              backgroundColor: tag.fields.colorCode,
              borderColor: tag.fields.colorCode,
            }}
          />
          {tag.fields.name}
        </Link>
      ))}
    </div>
  );
};
