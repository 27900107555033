import clsx from "clsx";
import { FC } from "react";
import { Anchor } from "@/components/Header/Anchor/Anchor";
import { Heading } from "@/components/Html/Heading";
import { Section } from "@/components/Html/Section";
import { Image } from "@/components/Image/Image";

export const Top: FC = () => {
  return (
    <Section className={clsx("kdot-container relative h-[600px] overflow-hidden")}>
      <Anchor id="top" />
      <Image
        width={3840}
        height={2160}
        alt=""
        src="/images/home/front.png"
        sizes="(max-width: 414px) 100vw, (max-width: 820px) 820px, (max-width: 896px) 896px, (max-width: 1180px) 1180px, (max-width: 1200px) 1200px, (max-width: 1920px) 1920px, (max-width: 2028px) 2028px, 2028px"
        quality={95}
        className="absolute right-0 top-0 h-[600px] w-full object-cover object-center mix-blend-multiply"
        priority
      />
      <hgroup className="absolute top-[35%] sm:top-[40%]">
        <Heading className="text-[45px] md:text-[57px]">
          <span>技術を</span>
          <br />
          <span className="max-sm:block">溢れる</span>
          <span className="max-sm:block">利益の源泉に</span>
        </Heading>
      </hgroup>
    </Section>
  );
};
