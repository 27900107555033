import clsx from "clsx";
import { FC } from "react";
import { NewsTag } from "@/lib/contentful/contentModel/newsTag";

interface Props {
  tags: DeepPick<NewsTag, "fields.slug" | "fields.colorCode" | "fields.name">[];
  className?: string;
}

export const NewsTags: FC<Props> = ({ tags, className }) => {
  return (
    <div className={clsx("flex flex-wrap items-center gap-[8px]", className)}>
      {tags.map((tag) => (
        <div className="flex items-center text-right text-[12px] hover:underline" key={tag.fields.slug}>
          <div
            className="mr-[4px] h-[8px] w-[8px] rounded-[4px] border border-solid border-gray-100"
            style={{
              backgroundColor: tag.fields.colorCode,
              borderColor: tag.fields.colorCode,
            }}
          />
          {tag.fields.name}
        </div>
      ))}
    </div>
  );
};
