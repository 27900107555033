import dayjs from "dayjs";
import Image from "next/image";
import Link from "next/link";
import { FC } from "react";
import { NewsTags } from "@/components/NewsTags/NewsTags";
import { useDayjsToString } from "@/components/useDayjsToString";
import { News } from "@/lib/contentful/contentModel/news";
import { getImageSize, getImageUrl } from "@/lib/contentful/util";
import { isNonNull } from "@/lib/guard";

interface Props {
  data: News;
}

export const Card: FC<Props> = ({ data }) => {
  const date = useDayjsToString(dayjs(data.fields.date), "YYYY/MM/DD");
  const newsUrl = `/news/${data.fields.slug}`;

  return (
    <div className="flex w-full flex-col overflow-hidden rounded-[12px] border border-gray-pale md:w-[300px]">
      <Link href={newsUrl}>
        {data.fields.image ? (
          <Image
            className="h-[160px] w-full border-b border-solid border-gray-pale object-cover"
            src={getImageUrl(data.fields.image)}
            width={getImageSize(data.fields.image)?.width}
            height={157}
            alt={String(data.fields.image.fields.title)}
            sizes="(max-width: 768px) 100vw, (max-width: 992px) 250px, 350px"
            quality={40}
          />
        ) : (
          <Image
            className="h-[320px] w-full border-b border-solid border-gray-pale object-cover"
            src="/images/kdotdev/banner.png"
            width={804}
            height={157}
            alt={"Blog Image"}
          />
        )}
      </Link>
      <div className="flex flex-1 flex-col gap-[16px] bg-white p-[12px]">
        <Link href={newsUrl} className="text-[16px] font-bold">
          {data.fields.title}
        </Link>
        <div>{data.fields.tags && <NewsTags tags={data.fields.tags.filter(isNonNull)} />}</div>
        <div className="mt-auto text-right font-roboto text-xs text-gray-600">{date}</div>
      </div>
    </div>
  );
};
