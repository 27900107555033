import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";

/**
 * Custom hook to convert Dayjs to a string
 *
 * In this project, dates are handled in UTC time to avoid basic discrepancies, but when displaying, it's necessary to convert them to local time using this hook.
 * @param date
 * @param accessor
 * @returns
 */
export function useDayjsToString(date: Dayjs, accesor: ((date: Dayjs) => string) | string) {
  const [result, setResult] = useState<string>("　");
  useEffect(() => {
    if (typeof accesor === "function") {
      setResult(accesor(date));
    } else {
      setResult(date.format(accesor));
    }
  }, [date, accesor]);

  return result;
}
