import clsx from "clsx";
import { FC } from "react";
import { Section } from "@/components/Html/Section";
import { News as NewsModel } from "@/lib/contentful/contentModel/news";
import { Title } from "../Title/Title";
import { Card } from "./Card/Card";

interface Props {
  news: NewsModel[];
}

export const News: FC<Props> = ({ news }) => {
  return (
    <Section className={clsx("kdot-container  bg-primary-pale !py-[48px]")}>
      <Title title="お知らせ" />
      <div className="flex flex-wrap justify-between gap-[32px]">
        {news.map((newsItem) => (
          <Card data={newsItem} key={newsItem.sys.id} />
        ))}
        {Array.from({ length: 6 - news.length }).map((_, i) => (
          <div key={i} className="w-[300px]" />
        ))}
      </div>
    </Section>
  );
};
